import request from '../utils/request'


export function getQiniuToken() {
    return request.post('./index.php', {
        function: 'getQiniuToken'
    })
}

export function createLoginPin(mobile) {
    return request.post('./index.php', {
        function: 'createLoginPin',
        mobile
    })
}

export function verifyLoginPin(mobile,pin) {
    return request.post('./index.php', {
        function: 'verifyLoginPin',
        mobile,pin
    })
}

export function updateVideo(id,user_id,video_name,video_intro,key) {
    return request.post('./index.php', {
        function: 'updateVideo',
        id,user_id,video_name,video_intro,key
    })
}

export function getMyVideos(user_id) {
    return request.post('./index.php', {
        function: 'getMyVideos',
        user_id
    })
}

export function stripHTML(html) {
    return request.post('./index.php', {
        function: 'stripHTML',
        html
    })
}

export function getVideo(id) {
    return request.post('./index.php', {
        function: 'getVideo',
        id
    })
}
